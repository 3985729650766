@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.product {
  max-width: 500px;

  img {
    width: 100%;
    min-height: 500px;
    object-fit: cover;
    margin-bottom: rem(20);
    overflow: hidden;
  }

  h3 {
    font-size: 24px;
  }

  @include media(tablet) {
    max-width: 100%;
  }
}