@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.grid {
  $root: &;
  display: grid;
  grid-gap: 48px;

  @include media(tablet-wide) {
    gap: 20px;
  }

  @include media(mobile-m) {
    grid-gap: 60px;
  }

  &--portfolio {

    .grid__item {
      &:nth-of-type(2) {
        padding-top: 0;
      }
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    justify-content: space-between;

    &:nth-child(2) {
      padding-top: 32px;

      @include media(tablet) {
        padding-top: 0;
      }
    }

    @include media(tablet) {
      &:nth-child(3) {
        grid-column: span 2;

        img {
          aspect-ratio: 16 / 7;
          object-position: center;
        }
      }
    }

    @include media(mobile-m) {
      &:nth-child(3) {
        grid-column: auto;
      }

      img {
        aspect-ratio: 16 / 7;
        object-position: center;
      }
    }
  }

  p {
    line-height: 1.4;
  }

  &[data-items="1"],
  &[data-items="2"] {
    --column: 3;

    margin-bottom: rem(65);

    grid-template-columns: repeat(var(--column), 1fr);

    @include media(tablet) {
      --column: 2;
    }

    @include media(mobile-m) {
      --column: 1;
    }
  }

  &[data-items="2"] {
    margin-bottom: rem(95);
  }

  &[data-items="3"] {
    grid-gap: 0;
    --column: 3;

    grid-template-columns: repeat(var(--column), 1fr);

    img {
      width: 100%;
      object-fit: cover;
    }

    @include media(tablet) {
      --column: 2;
    }

    @include media(mobile-m) {
      --column: 1;
    }
  }

  &[data-items="4"] {
    --column: 2;
    --row: 1;
    margin-bottom: rem(65);
    width: 100%;

    grid-template-columns: repeat(var(--column), 1fr);
    justify-content: space-between;

    .grid__item {
      &:nth-child(odd) {
        justify-self: start;
      }

      &:nth-child(even) {
        justify-self: end;
      }
    }

    @include media(mobile-m) {
      --column: 1;
    }
  }
}