@use "../../utils/functions/"as*;
@use "../../utils/mixins"as*;

.review-card {
  @include flex-v-center;
  flex-direction: column;
  text-align: center;
  max-width: 636px;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 50px;
  }

  img {
    max-width: 120px;
    width: 100%;
    height: 120px;
    filter: grayscale(100%);
    border-radius: 50%;
    margin-bottom: 16px;
  }

  &__author {
    display: block;
    font-family: var(--font-family-secondary);
    font-size: 21px;
    font-weight: var(--fw-700);
    margin-bottom: 20px;
  }

  &__blockquote {
    line-height: 1.55;
    font-size: 18px;
  }
}