@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.about {
  &--white-bg {
    background-color: var(--color-white);

    .post__content {
      @include media(tablet-wide) {
        background-color: var(--color-white);
      }
    }
  }

  h2 {
    max-width: 400px;
    line-height: 1.25;
    font-size: 42px;
    text-align: left;
    margin: 0;
    margin-bottom: 25px;

    @include media(tablet) {
      max-width: 100%;
    }

    @include media(mobile-m) {
      font-size: 28px;
    }
  }

  p {
    font-size: 20px;
    line-height: 1.22;
  }
}