@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.case {

  &__wrapper {
    @include cover-background;
  }

  .inner {
    min-height: 100vh;
    @include flex;
    flex-direction: column;
    justify-content: flex-end;

    @include media(tablet) {
      min-height: 600px;
    }

    @include media(mobile-l) {
      min-height: 400px;
    }
  }

  h2 {
    margin-inline: 0;
    margin-bottom: 20px;
    line-height: 1.18;
  }

  h2,
  p {
    max-width: 562px;
    z-index: 1;
    text-align: left;
  }

  p {
    font-size: 24px;
  }
}