@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  padding-top: 190px;

  @include media(tablet-wide) {
    padding-top: var(--header-height);
  }

  &__inner {
    @include cover-background;
    padding-block: 12px 10px;
  }

  &__content {
    @include flex-all-center;
    position: relative;
    flex-direction: column;
    text-align: center;
    padding-inline: 35px 30px;
    max-width: 570px;
    min-height: 100vh;
    z-index: 1;

    @include media(tablet) {
      max-width: 100%;
    }

    h1 {
      margin-bottom: 25px;
      line-height: 1.4;
      color: var(--color-white);
    }

    p {
      max-width: 330px;
      letter-spacing: 0.07rem;
      line-height: 1.25;
      margin-bottom: 58px;
      font-size: 22px;

      @include media(tablet) {
        max-width: 100%;
      }
    }
  }

  &--portfolio {
    p {
      max-width: 300px;
    }
  }

  &--simple,
  &--dark {
    padding-top: 192px;

    .hero__content {
      min-height: 567px;
      max-width: 675px;
      margin: 0 auto;
    }

    .hero__inner {
      padding-block: 0;
    }

    h1 {
      margin-bottom: 24px;
    }

    p {
      max-width: 100%;
      line-height: 1.35;
      margin-bottom: 54px;
    }
  }

  &--simple {

    h1,
    p {
      color: var(--color-brown-600);
    }
  }

  &--dark {

    h1,
    p {
      color: var(--color-white-600);
    }
  }

  @include media(tablet) {
    min-height: 700px;
    padding-top: 0;

    &__inner {
      min-height: 300px;
      padding: 0 30px;
    }

    &--simple,
    &--dark {
      padding-top: 208px;
    }
  }

  @include media(mobile-l) {
    padding-top: 0;

    &__content {
      max-width: 100%;
      padding: 20px;
    }

    &__inner {
      min-height: 300px;
      padding: 0;
    }

    &--simple,
    &--dark {
      padding-top: 208px;
    }
  }
}