@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.article {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 32px 1fr 32px;

  @include media(tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  &__image {
    width: 100%;
    position: relative;
    grid-row: 1/3;
    @include cover-background;

    @include media(tablet) {
      max-width: 1000px;
      min-height: 500px;
      grid-row: 1/2;
    }
  }

  &__image-overlay {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba($color: #000000, $alpha: 0.4)
    }
  }

  &__inner {
    min-height: 500px;
    align-self: flex-end;
    background-color: var(--color-brown-600);
    padding: 71px 124px;
    grid-row: 2/4;

    @include media(desktop) {
      padding: 71px 80px;
    }

    @include media(tablet-wide) {
      padding: 50px;
    }

    @include media(mobile-l) {
      padding: 30px 15px;
    }

    @include media(tablet) {
      min-height: auto;
      grid-row: 2/3;
    }

    p,
    h3 {
      color: var(--color-white);
    }
  }

  h3 {
    max-width: 283px;
    font-size: 20px;
    letter-spacing: 2.1;
    font-family: var(--font-family-primary);
    font-weight: var(--fw-500);
  }

  p {
    margin-bottom: 32px;
    line-height: 1.23;
  }

  &--light {
    .article__inner {
      background-color: var(--color-yellow-100);
      padding: 120px 124px 85px;
      @include flex-v-center;

      h3 {
        max-width: 100%;
        font-size: 20px;
        font-weight: var(--fw-500);
      }

      p {
        line-height: 1.22;
        font-size: 18px;
      }
    }

    p,
    h3 {
      color: var(--color-brown-900);
    }

    h3 {
      font-size: 24px;
      font-weight: var(--fw-500);
    }

    p {
      font-size: 18px;
    }
  }

  &__content {
    @include flex-col;
    max-width: 472px;

    @include media(tablet) {
      max-width: 100%;
    }
  }

  img {
    width: 100%;
    min-height: 501px;
    object-fit: cover;
    margin-bottom: rem(30);
  }

  &--no-btn {
    p {
      margin-bottom: 0;
    }

    .article__inner {
      padding: 100px 124px;

      @include media(tablet) {
        min-height: auto;
        grid-row: 2/3;
      }
    }
  }

  &--light,
  &--no-btn {
    .article__inner {
      @include media(desktop) {
        padding: 71px 80px;
      }

      @include media(tablet-wide) {
        padding: 50px;
      }

      @include media(mobile-l) {
        padding: 30px 15px;
      }
    }
  }
}